<template>
  <v-overlay
    color="black"
    opacity=".5"
  >
    <v-card
      class="warning-overlay-card"
      @wheel.prevent.stop
    >
      <!-- v-click-outside="cancel()" -->
      <v-icon class="warning-corner" color="grey darken-2">
        {{ warning_icon }}
      </v-icon>

      <h1>Warnung</h1>

      <div class="warning-line warning-head-space" />

      <p>
        {{ warning_text }}
      </p>

      <div class="warning-btns">
        <v-btn
          dark
          outlined
          color="white"
          @click="confirm()"
        >
          Bestätigen
        </v-btn>
        <v-btn
          dark
          outlined
          color="red darken-1"
          @click="cancel()"
        >
          Abbrechen
        </v-btn>
      </div>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  props: [
    'warning_text',
    'warning_icon',
  ],

  methods: {
    cancel() {
      this.$emit('cancel');
    },

    confirm() {
      this.$emit('confirm');
    },
  },
}
</script>

<style lang="css" scoped>
.warning-line {
  border-style: solid;
  border-color: white;
  border-width: 0 0 1px 0;
}
.warning-overlay-card {
  padding: 30px;
  border-style: solid;
  border-color: grey;
  border-width: 1px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  max-width: 400px;
}
.warning-btns {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
}
.warning-corner{
 position: absolute;
 top: 10px;
 right: 10px;
}
.v-btn {
  letter-spacing: normal;
  text-transform: none;
  flex-grow: 1;
}
h1 {
  text-align: center;
}
p {
  text-align: center;
}
</style>
