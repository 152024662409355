<template>
  <v-btn
    outlined
    dark
    :disabled="disabled"
    style="max-width: 130px;"
    @click="$refs.selectedImage.click();"
  >
    <input
      v-show="false"
      ref="selectedImage"
      type="file"
      @change="$emit('selectImage', { action: 'emit_selected_image', value: $refs.selectedImage.files[0] })"
    />
    Hochladen
  </v-btn>
</template>

<script>
/*
THIS DOENS'T ACTUALLY UPLOAD!
this component contains only the respective button and selection interface
use this component in addition to "ImageSelectMixin.mixin.js"!
there there are actual functions to handle the upload
*/
export default {
  props: [
    'disabled',
  ],

  methods: {

  },
}
</script>

<style lang="css" scoped>

</style>
