export const ImageSelectMixin = {
  data: () => ({
    new_image: {
      file: '',
      local_url: '',
      // name: '',
      // extension: '',
    },
    is_show_img_hint: false,
  }),

  methods: {
    selectImage(image) {
      let image_name = image.name;
      let arr = image_name.split(".");
      let ext = arr[arr.length-1].toLowerCase();

      if(ext == 'jpg' || ext == 'jpeg' || ext == 'png') {
        this.new_image.file = image;
        this.new_image.local_url = URL.createObjectURL(image);
        this.is_show_img_hint = false;
        // this.new_image.name = image.name;
        // this.new_image.extension = ext;

        return this.new_image.local_url; // we only return the local_url for display purposes
      }
      else {
        this.is_show_img_hint = true; // TODO: zeig dem user nen hint
      }
    },

    resetImageSelection() {
      this.new_image.file = '';
      this.new_image.local_url = '';
      this.is_show_img_hint = false;
    }
  },
};
