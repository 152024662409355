import WarningOverlay from '@/components/WarningOverlay.component.vue';

export const WarningOverlayMixin = {
  components: {
    WarningOverlay,
  },

  data: () => ({
    is_show_warning_overlay: false,
    warning: {
      text: '',
      icon: '',
    },
    cached_data_before_warning: {
      type: '', // type MUST be set
    },
  }),

  methods: {
    resetWarning(is_cancel) {
      // special case: we want to reset v-select when choice is not confiremd
      if(is_cancel && this.cached_data_before_warning.type == 'publish-category') {
        this.category_published = this.cached_data_before_warning.current_category.published;
      }

      this.cached_data_before_warning = {};
      this.is_show_warning_overlay = false;
    },

    showDeleteBlockOverlay(block, key) {
      this.warning.text = "Wollen Sie diesen Block wirklich löschen?";
      this.warning.icon = "mdi-delete";

      this.cached_data_before_warning.type = 'delete-block';
      this.cached_data_before_warning.block = block;
      this.cached_data_before_warning.key = key;

      this.is_show_warning_overlay = true;
    },

    showDeleteCategoryOverlay() {
      this.warning.text = "Wollen Sie diese Kategorie wirklich löschen?";
      this.warning.icon = "mdi-delete";

      this.cached_data_before_warning.type = 'delete-category';

      this.is_show_warning_overlay = true;
    },

    showDeleteArticleOverlay(category_id, article_id) {
      this.warning.text = "Wollen Sie diesen Artikel wirklich löschen?";
      this.warning.icon = "mdi-delete";

      this.cached_data_before_warning.type = 'delete-article';
      this.cached_data_before_warning.category_id = category_id;
      this.cached_data_before_warning.article_id = article_id;

      this.is_show_warning_overlay = true;
    },

    showPublishCategoryOverlay(current_category, category_published) {
      // let is_publish = current_category.published == 'public';
      let fill_string;
      let fill_icon;
      switch (category_published) {
        case 'public':
          fill_string = 'publizieren';
          fill_icon = 'mdi-check';
          break;
        case 'draft':
          fill_string = 'verstecken';
          fill_icon = 'mdi-close';
          break;
        case 'pro-user':
          fill_string = 'nur Pro-Usern zugänglich machen';
          fill_icon = 'mdi-check';
          break;
        default:
          fill_string = 'FEHLER'
      }
      // this.warning.text = `Wollen Sie diese Kategorie wirklich ${is_publish ? 'verstecken' : 'publizieren'}?`;
      this.warning.text = `Wollen Sie diese Kategorie wirklich ${fill_string}?`;
      this.warning.icon = fill_icon;

      this.cached_data_before_warning.type = 'publish-category';
      this.cached_data_before_warning.current_category = current_category;
      this.cached_data_before_warning.category_published = category_published;

      this.is_show_warning_overlay = true;
    },

    showPublishArticleOverlay(current_article) {
      let is_publish = current_article.published == 'public' || current_article.published == 'pro-user';
      this.warning.text = `Wollen Sie diesen Artikel wirklich ${is_publish ? 'verstecken' : 'publizieren'}?`;
      this.warning.icon = is_publish ? 'mdi-check' : 'mdi-close';

      this.cached_data_before_warning.type = 'publish-article';
      this.cached_data_before_warning.current_article = current_article;

      this.is_show_warning_overlay = true;
    },

    showDiscardCategoryChangesOverlay() {
      this.warning.text = 'Wollen Sie diese Änderungen verwerfen?';
      this.warning.icon = 'mdi-file-undo';

      this.cached_data_before_warning.type = 'discard-category';

      this.is_show_warning_overlay = true;
    },

    showDiscardArticleChangesOverlay() {
      this.warning.text = 'Wollen Sie diese Änderungen verwerfen?';
      this.warning.icon = 'mdi-file-undo';

      this.cached_data_before_warning.type = 'discard-article';

      this.is_show_warning_overlay = true;
    },

    showDiscardArticleArticleChangesOverlay() {
      this.warning.text = 'Wollen Sie diese Änderungen verwerfen?';
      this.warning.icon = 'mdi-file-undo';

      this.cached_data_before_warning.type = 'discard-article-article';

      this.is_show_warning_overlay = true;
    },

    confirmWarning() {
      let obj = this.cached_data_before_warning;
      switch (obj.type) {
        case 'delete-block':
          this.deleteBlock(obj.block, obj.key);
          break;
        case 'delete-category':
          this.deleteCategory();
          break;
        case 'delete-article':
          this.deleteArticle(obj.category_id, obj.article_id);
          break;
        case 'publish-category':
          this.publishCategory(obj.current_category, obj.category_published);
          break;
        case 'publish-article':
          this.publishArticle(obj.current_article);
          break;
        case 'discard-category':
          this.resetCurrentCategory();
          break;
        case 'discard-article':
          this.resetCurrentArticle();
          break;
        case 'discard-article-article':
          this.setArticle();
          break;
        default:

      }

      this.resetWarning();
    }
  },
}
